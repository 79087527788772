.container {
  width: 100%;
  /* max-width: 950px; */
  height: 100vh;
  background-color: #fff;
  box-sizing: border-box;
}

.alignCenter {
  margin-right: 16px;
  margin-left: 16px;
}

.title {
  font-size: 17px;
  font-weight: 500;
  line-height: 100%;
  margin: 0;
  padding: 10px;
}

.kakaoSignButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 300px;
  height: 48px;

  background-color: #1c5a91;
  border-radius: 16px;
  font-size: 20px;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5); */
}

.label {
  font-size: 16px;
  font-weight: 500;
  color: #fae103;
}

.chattingArea {
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
  height: 100%;

  overflow-y: scroll;
  padding: 32px;

  border-radius: 10px;
  box-sizing: border-box;
  margin-top: 160px;
}

.botArea {
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-top: 27px;
}

.window {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  margin-top: 60px;

  overflow-y: scroll;
  padding: 32px;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px; */
  border-radius: 10px;
  box-sizing: border-box;
}

.window[data-ismobile='true'] {
  /* height: 100%; */
  padding: 16px;
}
