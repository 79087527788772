.position {
  display: flex;
  justify-content: flex-end;
}

.markdownWrapper {
  color: #272727;
  font-feature-settings:
    'liga' off,
    'clig' off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;

  white-space: pre-wrap;
  word-break: break-word;
}

.markdownWrapper > * {
  /* 단어 단위 줄바꿈 */
  word-break: break-word;
  white-space: normal;
}

/* 마크다운 스타일 유지 */
.markdownWrapper strong {
  color: #272727;
  font-feature-settings:
    'liga' off,
    'clig' off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.32px;
}

.markdownWrapper em {
  font-style: italic;
}

.markdownWrapper p {
  /* margin-bottom: 1em;
  &:last-child {
    margin-bottom: 0;
  }
  word-break: break-word;
  white-space: pre-wrap; */

  color: #272727;
  font-feature-settings:
    'liga' off,
    'clig' off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;

  /* 단어 단위 줄바꿈 */
  word-break: break-all;
  white-space: pre-wrap;
}

.markdownWrapper code {
  background-color: #f0f0f0;
  padding: 2px 4px;
  border-radius: 4px;
}

.markdownWrapper pre {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}

.markdownWrapper blockquote {
  border-left: 4px solid #ccc;
  margin-left: 0;
  padding-left: 16px;
}

.markdownWrapper a {
  color: #0366d6;
  text-decoration: none;
}

.markdownWrapper a:hover {
  text-decoration: underline;
}

.markdownWrapper .highlight-blue {
  color: #007bff;
  font-weight: bold;
}

.markdownWrapper ul,
.markdownWrapper ol {
  padding-left: 20px;
}

.markdownWrapper img {
  max-width: 100%;
  height: auto;
}

.messageBox {
  /* max-width: 60%; */
  font-size: 15px;
  padding: 10px;
  border-radius: 8px;
  background-color: #1c5a91;
  line-height: 1.4;
}

.position[data-isbot='true'] {
  justify-content: flex-start;
}

.position[data-isbot='true'] .messageBox {
  background-color: #fff;
  border-top-left-radius: 0;
}
.position[is-document='true'] .messageBox {
  background-color: #dde5ea;
  /* background-color: #fff; */
}
.position[is-user='true'] .messageBox {
  border-top-right-radius: 0;
  display: flex;
  /* min-width: 80px; */
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-feature-settings:
    'liga' off,
    'clig' off;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
}

.message {
  white-space: pre-line;
}

.message {
  color: white;
}
.button {
}
.noButton {
  background: var(--GR_02, linear-gradient(180deg, #f6985d 0%, #f43c3c 100%));
  color: #fff;
  /* border: 1px solid var(--main-color); */
  border: none;
}
.yesButton {
  background: var(--GR_01, linear-gradient(180deg, #5d7cf6 0%, #4e3cf4 100%));
  color: #fff;
  /* border: 1px solid var(--main-color); */
  border: none;
}

.kakaoSignButton {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 16px;
  height: 40px;

  background-color: #ffe500;
  border-radius: 8px;
  font-weight: 500;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.kakaoSignButton[disabled] {
  background-color: #1c5a91;
  color: white;
}

.label {
  font-size: 15px;
  font-weight: 600;
}

.kakaoSignButton[disabled] .label {
  color: #f0f0f0;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
}

.input {
  padding: 8px;
  border: none;
  outline: none;
  background-color: #f0f0f0;
}

.submitButton {
  padding: 6px 12px;
  border-radius: 6px;
  background-color: #f0f0f0;
  color: #1c5a91;
}
