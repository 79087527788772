.container {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  box-sizing: border-box;
  overflow: hidden;
}

.title {
  font-size: 17px;
  font-weight: 500;
  padding: 10px;
}

.input {
  height: 48px;
  padding: 12px;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
}
.input:focus {
  outline: none;
  border: 1px solid #006fc1;
}
.input[filled='true'] {
  border: 1px solid #000;
}

.label {
  /*font-size: 16px;*/
  font-weight: 500;
  font-size: 20px;
  color: #5f5f5f;
  /* border: 1px solid #e0e0e0; */
}
.labelV2 {
  color: #fff;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
}

.submitButton {
  background-color: var(--main-color);
  width: 100%;
  color: white;
  height: 48px;
  font-size: 16px;
}

.agreements {
  font-size: 20px;
}

.inputRow {
  width: 100%;
}

.logoGroup {
  width: 90vw;
}
.customCheckbox {
  display: flex;
  align-items: center;
  /* cursor: pointer; */
}

.customCheckbox input[type='checkbox'] {
  display: none; /* 기본 체크박스를 숨깁니다 */
}
.customCheckbox:focus,
.customCheckbox:active {
  outline: none;
  background: none;
}

.customCheckbox .checkboxImage {
  width: 20px;
  height: 20px;
  background-image: url('../assets/icon/uncheckedBox.png'); /* 기본 이미지 경로 */
  background-size: cover;
  margin-right: 8px;
  cursor: pointer;
}

input[type='checkbox']:checked + .checkboxImage {
  background-image: url('../assets/icon/checkedBox.png'); /* 체크된 이미지 경로 */
}
