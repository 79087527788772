.item {
    width: 100%;
    height: 80px;

    box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px;
    background-color: white;
    border-radius: 6px;
}

.label {
    font-size: 12px;
    line-height: 100%;
}

.value {
    font-size: 24px;
    font-weight: 700;
    line-height: 100%;
    color: #1C5A91;
}

.unit {
    font-size: 16px;
    line-height: 130%;
}