.container {
  width: 100%;
  /* max-width: 950px; */
  height: 100vh;
  background-color: #fff;
  box-sizing: border-box;
}

.window {
  margin: 70px 16px 0;
}

.chart {
  height: 500px;
  padding: 30px;

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px;
  border-radius: 6px;
}

.chart[data-ismobile='true'] {
  height: 400px;
  padding: 12px 16px;
}
