.album {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 4px;
}

.album[data-ismobile=true] {
    grid-template-columns: 1fr 1fr 1fr;
}

.image {
    border: 1px solid lightgray;
}