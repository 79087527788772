.mobileContainer {
  width: 100%;
  height: 100vh;
  font-family: 'Pretendard';
  background-color: #fff;
  overflow: hidden;
}

.mobileHeader {
  width: 100%;
  height: 42px;
  position: fixed;
  top: 0;
  background-color: #fff;
  z-index: 10;
}

.drawerBody {
  padding: 16px !important;
}

.category {
  font-size: 17px;
  font-weight: 600;
  color: #000;
  margin-bottom: 3px;
}

.homeButton {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 16px;
}

.dashboardButton {
  display: flex;
  align-items: center;

  padding: 0;

  font-size: 17px;
  font-weight: 500;
  line-height: 100%;
}

.dashboardIcon {
  font-size: 22px;
  color: #1c5a91;
}

.body {
  width: 100%;
  margin-top: 42px;
}

.submitButton {
  /* color: #00be29; */
}
