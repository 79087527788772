.alignCenter {
  margin-right: 16px;
  margin-left: 16px;
}
.headerArea {
}
.content {
  margin: 0 10px 0;
}

.title,
.highlight {
  font-size: 17px;
  font-weight: 500;
  line-height: 100%;
  margin: 0;
  padding: 10px;
}

.highlight {
  color: #1c5a91;
}

.window[data-ismobile='true'] {
  /* height: 100%; */
}

.inputArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  width: 100%;
  height: 48px;

  padding: 0 16px;

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px;
  border-radius: 10px;
}

.input {
  width: 100%;
  height: 18px;
  line-height: 15px;

  resize: none;
  border: none;
  outline: none;
  font-size: 15px;
}

.input::placeholder {
  color: #828282;
}

.sendButton {
  background-color: #1c5a91;
  width: 40px !important;
}

.recordingButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px !important;
  height: 48px !important;

  background-color: #1c5a91 !important;
  border-radius: 12px;
  border: 3px solid white;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 8px;
}

.nameTag {
  position: absolute;
  top: -35px;
  left: 0;
  z-index: 1;
}

.botIcon,
.refreshIcon {
  font-size: 25px;
  color: #1c5a91;
}

.refreshIcon {
  color: black;
}

.botArea {
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-top: 20px;
}

.userArea {
  display: flex;
  justify-content: flex-end;
}

.botWrapper,
.userWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 30rem;
}

.userWrapper {
  align-items: flex-end;
}
.botAudioPlayer,
.userAudioPlayer {
  max-width: 45%;
  font-size: 10px;
  border-radius: 8px;
  padding: 5px;
  box-shadow: none;
}
.botAudioPlayer {
  border-top-left-radius: 0;
}
.userAudioPlayer {
  border-top-right-radius: 0;
  background-color: var(--main-color);
}

.assistantIcon {
  width: 32px;
  z-index: 0;
}

.sendBtn {
  width: 28px;
}

.footerArea {
  margin: 0;
  box-sizing: border-box;
  padding: 16px 20px;
  height: 80px;
  background-color: #abbecd;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9;
  @media screen and (min-width: 768px) {
    width: calc(100% - 240px);
  }
}

.voiceIcon {
  width: 60px;
}
