.container {
    width: 100%;
    max-width: 950px;
    height: 100%;
}

.alignCenter {
    margin-right: 16px;
    margin-left: 16px;
}

.title, .highlight {
    font-size: 24px;
    font-weight: 700;
    line-height: 100%;
    margin: 0;
}

.highlight {
    color: #1C5A91;
}

.window
{
    display: flex;
    flex-direction: column;
    gap: 10px;

    width: 100%;
    height: 500px;

    overflow-y: scroll;
    padding: 32px;

    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px;
    border-radius: 10px;
}

.window[data-ismobile=true] {
    height: 440px;
    padding: 16px;
}

.inputArea
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    width: 100%;
    height: 48px;

    padding: 0 16px;

    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px;
    border-radius: 10px;
}

.input {
    width: 100%;
    height: 18px;
    line-height: 18px;

    resize: none;
    border: none;
    outline: none;
}

.input::placeholder {
    color: #1C5A91;
}

.sendButton {
    background-color: #1C5A91;
    width: 40px !important;
}

.recordingButton {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 48px !important;
    height: 48px !important;

    background-color: #1C5A91 !important;
    border-radius: 12px;
    border: 3px solid white;
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 8px;
}

.nameTag {
    position: absolute;
    top: -30px;
    left: 0;
    z-index: 10;
}

.botIcon, .refreshIcon {
    font-size: 25px;
    color: #1C5A91;
}

.refreshIcon {
    color: black;
}

.botArea {
    display: flex;
    justify-content: flex-start;
    position: relative;
}

.userArea {
    display: flex;
    justify-content: flex-end;
}

.botWrapper, .userWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 30rem;
}

.userWrapper {
    align-items: flex-end;
}

.botAudioPlayer, .userAudioPlayer  {
    max-width: 45%;
    font-size: 10px;
    border-radius: 10px;
    padding: 5px;
}