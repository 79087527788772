.container {
  width: 100%;
  /* max-width: 950px; */
  height: 100vh;
  background-color: #fff;
  box-sizing: border-box;
}

.category {
  font-size: 20px;
  font-weight: 700;
  color: #1c5a91;
}

.window {
  margin: 60px 16px 0;
}
