.container {
  width: 100%;
  /* max-width: 950px; */
  height: 100vh;
  background-color: #fff;
  box-sizing: border-box;
}

.window {
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 100%;
  margin: 0;

  padding-left: 10px;
}

.searchContainer {
}
